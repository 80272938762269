import type { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import type { IWixAPI } from '@wix/yoshi-flow-editor';

export interface TranslatedPricingDetails {
  price: string;
  cycle: string;
  duration: string;
  freeTrial: string;
}

type NavigateToSectionOptions = {
  appDefinitionId: string;
  sectionId: string;
  queryParams?: Object;
  state?: string;
};

type NavigationPolicy =
  | { type: 'url'; url: string }
  | { type: 'section'; options: NavigateToSectionOptions }
  | { type: 'page'; pageId: string };

type SuccessStatusContent = {
  title: string;
  message?: string;
  cta: string;
};

type SuccessStatusOptions = {
  content?: SuccessStatusContent;
  navigation?: NavigationPolicy;
};

type BiOptions = {
  referralInfo: string;
  referralId?: string;
};

type CheckoutOptions = {
  minStartDate?: string; // yyyy-MM-dd
  maxStartDate?: string; // yyyy-MM-dd
  successStatus?: SuccessStatusOptions;
};

export type NavigateToCheckoutOptions = {
  planId: string;
  biOptions?: BiOptions;
} & CheckoutOptions;

export type NavigateToPackagePickerOptions = {
  planIds?: string[];
  title?: string;
  subtitle?: string;
  biOptions?: BiOptions;
  checkout?: CheckoutOptions;
};

export interface PricingPlansPublicAPI {
  getTranslatedPricingDetails(plan: PublicPlan): TranslatedPricingDetails;
  navigateToPackagePicker(options: NavigateToPackagePickerOptions): void;
  navigateToCheckout(options: NavigateToCheckoutOptions): void;
}

/**
 * Retrieves Pricing Plans public API using wixCodeApi.site.getPublicAPI.
 *
 * May throw if Pricing Plans is not installed in the site.
 */
export function getPricingPlansPublicAPI(wixCodeApi: IWixAPI): Promise<PricingPlansPublicAPI> {
  return wixCodeApi.site.getPublicAPI('1522827f-c56c-a5c9-2ac9-00f9e6ae12d3');
}
